import confetti from "./universes_anims/confetti";

// loader
window.addEventListener("DOMContentLoaded", (_event) => {
  if (document.body.classList.contains("nuit-blanche-2023")) {
    initScrollAnimation();
    overrideSocialTitle();
  }

  if (document.body.classList.contains("mon-premier-festival-2023") || document.body.classList.contains("mon-premier-festival-2024")) {
    if (window.FontFaceSet) {
      document.fonts.ready.then(() => {
        splitTitle();
      });
    } else {
      splitTitle();
    }
  }


  if (document.body.classList.contains("nuit-blanche-2024")) {
    animateOnLoad();
  }
});

  if (document.body.classList.contains("mon-premier-festival-2024")) {
    initConfetti();
  }


// NUIT BLANCHE 2024

function animateOnLoad() {
  const media = window.matchMedia("(max-width: 799px)").matches ? "mobile" : "desktop";

  const unverseHeader = document.querySelector(".universe--header");
  const headerImages = unverseHeader.querySelectorAll(`.header-container--${media} img`);

  Promise.all(
    Array.from(headerImages).map((img) => {
      if (img.complete) return Promise.resolve(true);
      return new Promise((resolve) => {
        img.addEventListener("load", () => resolve(true));
        img.addEventListener("error", () => resolve(false));
      });
    })
  ).then(() => {
    unverseHeader.classList.add("animate");
  });
}

// NUIT BLANCHE 2023

// Scroll animation

const isLarge = () => window.matchMedia("(min-width: 800px)").matches;
const currentFrame = (index) =>
  `https://cdn.paris.fr/paris/universes/nuit-blanche/2023/images/header_animation/nb23-tiger-frame-${index
    .toString()
    .padStart(2, "0")}.png`;

function preloadImages(frameStart, frameEnd) {
  for (let i = frameStart; i <= frameEnd; i++) {
    const img = new Image();
    img.src = currentFrame(i);
  }
}

function initImage(canvas, frameStart) {
  const context = canvas.getContext("2d");
  const img = new Image();

  img.src = currentFrame(frameStart);
  img.onload = function () {
    const sizeFactor = isLarge() ? 1.25 : 1;
    canvas.width = this.naturalWidth * sizeFactor;
    canvas.height = this.naturalHeight * sizeFactor;
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
  };

  return img;
}

function updateImage(canvas, img, index) {
  const context = canvas.getContext("2d");

  // console.debug("DRAW", index)
  img.src = currentFrame(index);
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(img, 0, 0, canvas.width, canvas.height);
}

function initScrollAnimation() {
  const html = document.documentElement;
  const title = document.querySelector(".anim-title");
  const canvas = document.querySelector(".anim-canvas");
  const layerGreen = document.querySelector(".prlx-layer-green");
  const layerGreenExtra = document.querySelector(".prlx-layer-green-extra");
  const layerLotus = document.querySelector(".prlx-layer-lotus");
  const layerPink = document.querySelector(".prlx-layer-pink");
  const layerPinkExtra = document.querySelector(".prlx-layer-pink-extra");

  const frameStart = 7;
  const frameEnd = 28;
  let lastDrawnFrame = frameStart;

  const titleStartScale = 1;
  const titleEndScale = 1.2;

  const layerGreenEndMove = 100;
  const layerLotusEndMove = 10;
  const layerPinkEndMove = 50;

  const img = initImage(canvas, frameStart);

  preloadImages(frameStart + 1, frameEnd);

  const scrollListener = (_e) => {
    const scrollTop = html.scrollTop;
    let maxScrollTop = canvas.height;
    maxScrollTop += isLarge() ? 100 : 0;
    const scrollFraction = scrollTop / maxScrollTop;

    const frameIndex = Math.min(frameEnd - 1, frameStart + Math.ceil(scrollFraction * (frameEnd - frameStart))) + 1;
    const titleScale = Math.min(titleEndScale, titleStartScale + scrollFraction * (titleEndScale - titleStartScale));
    const layerGreenMove = Math.min(layerGreenEndMove, scrollFraction * layerGreenEndMove);
    const layerLotusMove = Math.min(layerLotusEndMove, scrollFraction * layerLotusEndMove);
    const layerPinkMove = Math.min(layerPinkEndMove, scrollFraction * layerPinkEndMove);

    // canvas
    if (lastDrawnFrame !== frameIndex) {
      requestAnimationFrame(() => {
        updateImage(canvas, img, frameIndex);
      });
      lastDrawnFrame = frameIndex;
    }

    requestAnimationFrame(() => {
      // title
      title.style.transform = `scale(${titleScale})`;

      // parallax
      layerGreen.style.transform = `translate3d(0, ${layerGreenMove}px, 0)`;
      layerGreenExtra.style.transform = `translate3d(0, ${layerGreenMove}px, 0) scale(${titleScale})`;
      layerLotus.style.transform = `translate3d(0, ${layerLotusMove}px, 0)`;
      layerPink.style.transform = `translate3d(0, -${layerPinkMove}px, 0)`;
      layerPinkExtra.style.transform = `translate3d(0, -${layerPinkMove}px, 0) scale(${titleScale})`;
    });
  };

  window.addEventListener("scroll", scrollListener);
}

function overrideSocialTitle() {
  const socialSection = document.querySelector(".qfap--social");
  const socialTexts = {
    instagram: "@quefaireaparis",
    twitter: "@QueFaireAParis",
    facebook: "@NBParis",
    tiktok: "@villedeparis",
  };

  if (socialSection) {
    Object.keys(socialTexts).forEach((social) => {
      const socialTitle = socialSection.querySelector(`.has-${social}-network .qfap--social-title`);

      if (socialTitle) {
        socialTitle.textContent = socialTexts[social];
      }
    });
  }
}

// MON PREMIER FESTIVAL 2022, 2023, 2024

function splitTitle() {
  const headerElem = document.querySelector(".universe--header");
  const titleElem = headerElem.querySelector(".universe--header-title h1");
  titleElem.innerHTML = titleElem.innerHTML
    .split("<br>")
    .map((s) => s.replace(/(\S)/g, "<span>$&</span>"))
    .join("<br>");
  titleElem.hidden = false;
  headerElem.classList.add("animate");
}

function initConfetti() {
  document.addEventListener("DOMContentLoaded", function() {
    var canvas = document.querySelector("canvas.confettiCanvas");

    canvas.confetti = canvas.confetti || confetti.create(canvas, { resize: true });

    var random_in_range = function(min, max) {
      return Math.random() * (max - min) + min;
    };

    var colors = ["#2493C6", "#D5217C", "#F8EC41"];
    var shapes = [
      confetti.shapeFromPath({
        path: "M13.88 25.22c-3.24 0-5.72-2.79-7.45-4.75a19.3 19.3 0 0 0-2.13-2.18 3.68 3.68 0 0 1-1.84-7.1c4.24-1.51 7.4 2.04 9.48 4.39l1.13 1.25c.05-.58.08-1.21.1-1.68.14-3.13.36-7.87 4.61-9.38 4.24-1.5 7.39 2.04 9.48 4.39.32.36.73.82 1.13 1.24.05-.58.08-1.2.1-1.67.14-3.14.36-7.87 4.61 9.37-4.25 1.5-7.39-2.05-9.48-4.39-.32-.36-.73-.82-1.13-1.24-.05.58-.08 1.2-.1 1.68-.14 3.14-.36 7.87-4.61 9.38a6 6 0 0 1-2.02.36Z",
        matrix: [1, 0, 0, 1, 0, 0],
      }),
      confetti.shapeFromPath({
        path: "M10.79 0 1.15 4.24 0 14.71l8.49 6.23 9.65-4.24 1.15-10.47L10.79 0z",
        matrix: [1, 0, 0, 1, 0, 0],
      }),
      confetti.shapeFromPath({
        path: "M0 9.7 13.42 0l15.95 22.06-13.42 9.7z",
        matrix: [1, 0, 0, 1, 0, 0],
      }),
      confetti.shapeFromPath({
        path: "M14.2 7.1A7.1 7.1 0 1 1 0 7.1a7.1 7.1 0 0 1 14.2 0",
        matrix: [1, 0, 0, 1, 0, 0],
      }),
      confetti.shapeFromPath({
        path: "m26.9 7.61-2.28 8.17-.32 8.47-8.47.36-8.16 2.3-2.96-7.94L0 11.93l6.64-5.27L11.88 0l7.06 4.69 7.96 2.92z",
        matrix: [1, 0, 0, 1, 0, 0],
      }),
    ];

    var confetti_frame = function() {
      canvas.confetti({
        particleCount: 1,
        startVelocity: 0,
        spread: 70,
        ticks: 350,
        origin: {
          x: Math.random(),
          y: -0.1,
        },
        flat: true,
        rotate: true,
        colors: [colors[Math.floor(Math.random() * colors.length)]],
        shapes: [shapes[Math.floor(Math.random() * shapes.length)]],
        scalar: 0.8,
        gravity: random_in_range(0.4, 0.6),
        drift: random_in_range(-0.4, 0.4)
      });

      setTimeout(() => {
        requestAnimationFrame(confetti_frame);
      }, window.innerWidth <= 800 ? 700 : 400);
    };

    confetti_frame();
  });
}
